import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import BoutonAcheterTirageAvecPrix from "../../../composants/boutique/BoutonAcheterTirageAvecPrix"
import Image from "../../../composants/image/Image"
import LayoutGalerieImageInteractive from "../../../composants/layout/LayoutGalerieImageInteractive"
import Pannellum from "../../../composants/image/Panellum"

import {
  obtenirPage,
  obtenirImage,
  obtenirImages,
} from "../../../js/client-es/utils"
import fragmentsPages from "../../../js/client-es/fragments/fragments-pages"

export const requete = graphql`
  {
    page: allPagesCompilationYaml(
      filter: { code: { eq: "voieLacteeIntegralite360" } }
    ) {
      ...FragmentPageYaml
    }
    imageAVendre: allImagesCompilationYaml(
      filter: { code: { in: ["voieLacteeIntegralite"] } }
    ) {
      nodes {
        ...FragmentDonneesImages
      }
    }
    imagesPaysageMoyen: allImagesCompilationYaml(
      filter: { code: { in: ["boutiqueVoieLacteeCaisseAmericaine"] } }
    ) {
      ...FragmentImagePaysageMoyen
    }
  }
`

export default function VoieLacteeIntegralite360(props) {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(resultatsRequete, "page")
  const images = obtenirImages(
    resultatsRequete,
    "imageAVendre",
    "imagesPaysageMoyen"
  )

  const composantImageInteractive = (
    <Pannellum
      urlImageSource="/ressources/360/voieLacteeIntegralite.jpg"
      altitudeParDefaut={0}
      angleDeVue={80}
      angleDeVueVertical={50}
    />
  )

  return (
    <LayoutGalerieImageInteractive
      page={page}
      propsPage={props}
      composantImageInteractive={composantImageInteractive}
      codePageRetour="galerieZoomsEt360Degres"
    >
      <section className="section-conteneur">
        <p>
          Cette image est le fruit d’un travail collaboratif d’un an et demi
          réalisé avec ma compagne. Un an et demi de prises de vues, du Chili à
          la Bretagne, à prendre en photo chaque partie de la Voie Lactée alors
          qu’elle se trouvait à son zénith, lors de nuits aux conditions
          parfaites.
        </p>
        <p>
          Cette image révèle ce que vous verriez si vous vous mettiez à la place
          de notre Soleil. Oui, imaginez, vous y êtes. Vous regardez tout autour
          de vous. Il fait nuit noire. Que voyez-vous ? C’est la Voie Lactée,
          notre galaxie aux 100 milliards de Soleils. Vous la voyez par la
          tranche, car notre système Solaire se trouve dans le disque
          galactique.
        </p>
        <div id="galerie-image-interactive-texte-exifs">
          Canon EOS 6D refiltré Astrodon, Sigma 24 mm Art F1.4@F2. Mosaïque de
          12 images. Temps de pose total : 22h40.
        </div>
        <div id="galerie-image-interactive-conteneur-achat-tirage-avec-prix">
          <BoutonAcheterTirageAvecPrix
            image={obtenirImage(images, "voieLacteeIntegralite")}
            langue={page.langue}
          />
        </div>
        <Image
          image={obtenirImage(images, "boutiqueVoieLacteeCaisseAmericaine")}
          langue={page.langue}
        />
      </section>
    </LayoutGalerieImageInteractive>
  )
}
